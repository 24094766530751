import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform=" ">

<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M521.525879,367.781433 
	C550.226135,370.356445 573.566711,383.011841 592.255371,403.798187 
	C617.109497,431.442108 626.153137,464.206177 619.366699,500.659454 
	C610.740356,546.995728 582.584351,576.385498 537.897766,589.924133 
	C490.783417,604.198242 433.886261,581.297363 410.439880,539.157288 
	C409.235779,536.993103 408.431946,534.603088 407.478851,532.302917 
	C404.485657,525.078918 399.294952,520.210144 391.752197,518.142761 
	C381.737610,515.397827 374.822083,508.148895 373.928436,499.094635 
	C372.824158,487.905945 377.667877,478.652832 386.918610,473.591125 
	C390.252930,471.766663 393.904327,471.520599 397.401947,470.528961 
	C412.746796,466.178406 419.048004,448.280792 409.704987,435.670654 
	C408.062286,433.453491 405.970856,431.852783 403.633759,430.573669 
	C392.371399,424.409515 392.727692,408.828766 398.048309,401.677490 
	C404.560516,392.924683 417.216248,391.547241 426.432190,398.906433 
	C433.411011,404.479309 441.067047,405.233307 448.925232,401.559479 
	C456.912781,397.825134 461.370697,391.323120 462.041107,382.343781 
	C462.394135,377.615814 460.695770,373.140167 460.701904,368.452881 
	C460.717682,356.380035 467.795715,347.352142 479.840240,344.269989 
	C488.896088,341.952667 502.330597,347.941498 505.775665,356.040588 
	C508.514374,362.479126 514.214478,366.156586 521.525879,367.781433 
M507.327087,427.365479 
	C500.660126,430.264893 493.702667,432.330933 487.992889,437.392853 
	C476.979248,447.156799 474.712769,460.147919 474.485962,473.536560 
	C474.156311,492.996002 474.940826,512.472412 475.051422,531.942749 
	C475.076538,536.364624 476.926270,537.521118 480.906799,536.764832 
	C494.074158,534.263123 503.834076,522.578552 503.848755,509.221893 
	C503.862640,496.565704 503.309326,483.875488 504.060699,471.263062 
	C504.791443,458.996765 509.767944,454.712555 518.982361,456.040497 
	C524.725891,456.868225 528.546936,461.525269 528.186401,467.211761 
	C527.834961,472.753235 523.731445,476.523407 517.984314,476.564728 
	C515.407410,476.583252 512.830200,476.567841 509.415314,476.567841 
	C509.415314,484.866882 509.393097,493.000122 509.430939,501.133118 
	C509.440430,503.174683 510.321533,504.650848 512.546570,504.994324 
	C517.164246,505.707123 521.976990,506.202057 526.390564,505.060730 
	C545.085022,500.226410 559.777527,484.630920 558.109924,463.745087 
	C557.144165,451.648499 552.141113,442.246613 542.289001,435.215057 
	C531.974609,427.853577 520.439026,426.081757 507.327087,427.365479 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M457.402710,385.895020 
	C454.222534,396.997192 443.020538,402.598969 430.663208,398.754578 
	C421.299347,395.841461 415.809479,385.397888 418.011810,375.008240 
	C420.217133,364.604340 432.504486,356.938385 442.914673,359.471497 
	C454.365845,362.257965 460.548401,373.283569 457.402710,385.895020 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M394.471069,429.098785 
	C402.341339,432.951569 407.280670,438.200714 407.416138,447.299072 
	C407.541138,455.692719 405.031616,462.327118 397.434814,466.427704 
	C389.730011,470.586639 378.883209,469.252075 373.218079,463.601471 
	C366.994537,457.393982 365.521851,445.910004 369.944611,438.074646 
	C374.279358,430.395142 383.131470,426.563324 392.165955,428.463501 
	C392.816315,428.600342 393.454803,428.793671 394.471069,429.098785 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M508.762817,638.739319 
	C508.732178,629.629150 508.734650,620.995056 508.716553,612.360962 
	C508.711456,609.926331 508.831940,607.040894 511.960968,607.262451 
	C519.041504,607.763794 526.295837,606.554321 533.193481,608.914673 
	C541.277527,611.681030 545.821289,618.522522 545.021851,626.328735 
	C544.181091,634.538391 537.891296,641.196960 529.460938,642.005127 
	C523.863464,642.541748 518.183167,642.229919 512.539734,642.264587 
	C510.491394,642.277161 508.958832,641.559570 508.762817,638.739319 
M526.055420,616.109192 
	C524.081787,616.170593 521.954041,615.782837 520.169373,616.395020 
	C517.230896,617.403015 518.104187,620.362000 518.345642,622.438049 
	C518.780334,626.176575 516.111755,630.898743 520.057739,633.498413 
	C524.399414,636.358765 532.817139,632.807007 534.480164,628.149780 
	C536.475342,622.562500 533.617188,618.056702 526.055420,616.109192 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M439.960846,633.278442 
	C436.663696,633.783264 437.198242,636.141174 437.158813,638.017517 
	C437.090149,641.284607 435.370911,642.284485 432.356323,642.296021 
	C429.250610,642.307861 427.715729,641.120605 427.730804,637.902649 
	C427.771362,629.240723 427.721649,620.578308 427.701660,611.916138 
	C427.695984,609.449951 428.413086,607.622620 431.336639,607.669434 
	C436.663361,607.754639 442.045868,607.377136 447.305389,608.014832 
	C458.564789,609.380066 462.868347,620.293640 455.615784,628.991028 
	C453.658630,631.338074 453.829468,632.735046 455.449005,634.968262 
	C456.755341,636.769592 458.597473,638.425720 458.756409,640.901245 
	C453.609253,643.389282 450.965149,642.770447 447.551056,638.728577 
	C445.584961,636.400940 444.243591,633.096069 439.960846,633.278442 
M442.207855,625.368774 
	C447.820557,624.312439 450.058167,622.204468 448.692505,618.900635 
	C447.215790,615.328186 443.783417,615.456055 440.668274,615.548157 
	C438.042114,615.625732 437.087982,617.340881 437.060883,619.888489 
	C437.027618,623.015564 437.423462,625.601196 442.207855,625.368774 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M384.724304,385.970398 
	C383.139465,398.958496 373.069458,405.636444 362.894348,400.829865 
	C355.723450,397.442444 352.725922,390.534943 354.731812,382.020142 
	C356.223694,375.687256 362.300079,371.328491 369.689850,371.290344 
	C376.973694,371.252747 382.834991,376.145630 384.432007,383.608795 
	C384.570557,384.256317 384.643066,384.917938 384.724304,385.970398 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M341.299133,466.126831 
	C348.624207,468.349792 352.735474,472.876221 353.070129,480.226593 
	C353.354980,486.483765 351.103149,491.587067 345.266327,494.630676 
	C339.562897,497.604614 332.003876,496.938873 327.474609,493.076385 
	C322.880615,489.158691 321.095947,481.409332 323.358215,475.202301 
	C325.370605,469.680817 331.244873,465.903656 337.916107,465.869720 
	C338.908783,465.864685 339.902039,465.973999 341.299133,466.126831 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M378.831085,633.995483 
	C381.388092,634.115234 383.732330,633.293762 385.310059,635.419678 
	C386.409760,636.901428 386.199921,638.589844 385.676208,640.208679 
	C385.120758,641.925476 383.666962,642.273071 382.077362,642.272339 
	C375.416870,642.269104 368.756287,642.231934 362.095978,642.262512 
	C358.881531,642.277283 357.739624,640.682495 357.778870,637.580750 
	C357.886230,629.089844 357.873352,620.596497 357.809296,612.104858 
	C357.785645,608.966797 359.074707,607.552612 362.279968,607.610840 
	C368.605591,607.725952 374.935333,607.746643 381.261261,607.657288 
	C384.394287,607.613037 385.337677,609.173035 385.218842,611.998047 
	C385.102478,614.764587 383.651978,615.809631 381.023346,615.701050 
	C377.866974,615.570679 374.707214,615.480530 371.548462,615.463501 
	C369.686768,615.453430 367.604126,615.506958 367.473511,618.040161 
	C367.342041,620.590332 369.352386,620.871033 371.258667,620.941040 
	C373.920013,621.038696 376.585571,621.015503 379.248901,621.067627 
	C381.844879,621.118408 383.227142,622.284851 383.193176,625.060364 
	C383.158844,627.866272 381.673096,628.911560 379.110291,628.924805 
	C376.285095,628.939331 373.458374,628.895569 370.635132,628.975647 
	C369.082977,629.019653 367.513306,629.332214 367.441071,631.348206 
	C367.355255,633.743225 369.158905,633.886902 370.873322,633.935852 
	C373.369049,634.007202 375.867523,633.982971 378.831085,633.995483 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M575.099792,620.967041 
	C578.112488,627.800842 581.469543,634.091614 583.809753,641.273315 
	C578.954041,642.755127 575.335693,643.352844 572.504761,638.202759 
	C569.929382,633.517517 557.588867,634.190002 555.387512,639.020264 
	C553.437683,643.298645 550.083191,642.433533 546.903748,642.015808 
	C544.677856,641.723267 544.696167,639.961670 545.530457,638.157898 
	C549.793030,628.942322 554.098633,619.745361 558.226196,610.469360 
	C559.492004,607.624573 561.698242,607.244812 564.304871,607.141113 
	C567.097595,607.029968 569.044250,607.985229 570.203430,610.710632 
	C571.635864,614.078674 573.353882,617.325317 575.099792,620.967041 
M567.213562,625.811768 
	C566.396912,623.793701 566.213806,621.376770 563.744690,619.892334 
	C562.739929,622.486694 560.158630,624.567810 562.069336,626.962463 
	C563.164246,628.334778 565.665649,628.561157 567.213562,625.811768 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M396.876709,617.914062 
	C397.896240,615.587769 398.833496,613.632690 399.614105,611.616943 
	C400.701080,608.810120 402.501373,607.535217 405.670380,607.523560 
	C408.888336,607.511780 410.986420,608.421326 412.354858,611.471924 
	C416.100739,619.822327 420.077240,628.069092 423.856995,636.404663 
	C424.607056,638.058716 426.788666,640.374268 424.315338,641.621216 
	C421.763733,642.907837 417.447357,643.599731 416.160461,640.659729 
	C413.445374,634.456848 408.607208,635.321228 403.667603,635.205872 
	C400.284760,635.126953 398.013092,636.146606 396.643036,639.463867 
	C395.017029,643.400879 391.404327,642.636780 388.453094,641.815491 
	C385.883484,641.100403 387.069519,638.902283 387.836609,637.251038 
	C390.781403,630.911865 393.759064,624.587891 396.876709,617.914062 
M407.703522,627.709229 
	C411.066803,624.239929 406.850433,622.615601 406.076202,618.866333 
	C404.488495,622.336243 402.594299,624.274414 402.958344,627.037598 
	C404.355621,628.055298 405.721436,627.695984 407.703522,627.709229 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M322.632111,616.387817 
	C322.587372,614.274109 322.474579,612.612915 322.511017,610.955078 
	C322.546417,609.342651 323.091980,607.755615 324.971222,607.817810 
	C332.046936,608.051880 339.270752,606.406738 346.148712,609.343262 
	C351.261627,611.526245 353.922333,615.398621 353.804230,620.453491 
	C353.674561,626.003174 350.941772,629.820007 345.677307,631.816284 
	C342.835358,632.893860 339.879852,633.284668 336.868378,633.128174 
	C333.656158,632.961365 331.688171,634.009338 331.982147,637.611389 
	C332.339447,641.989868 329.576996,642.375000 326.210907,642.260925 
	C322.291656,642.128113 322.394958,639.740479 322.496490,636.773560 
	C322.723602,630.135925 322.606354,623.486389 322.632111,616.387817 
M332.038177,621.379089 
	C332.650208,626.846069 336.738220,624.906311 339.714325,624.772217 
	C342.351471,624.653503 344.128204,622.949280 343.781708,620.128235 
	C343.226868,615.611511 339.491150,615.685364 336.199829,615.391724 
	C332.387299,615.051636 331.816406,617.520630 332.038177,621.379089 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M469.664825,608.245972 
	C475.164581,606.817871 480.296265,606.982544 485.356171,608.323486 
	C487.705841,608.946167 489.866455,610.103027 488.892487,613.242004 
	C487.960968,616.244141 486.315765,617.595703 482.904266,616.394104 
	C479.381622,615.153381 475.590607,613.777893 472.410126,617.336975 
	C472.935913,620.134705 475.257416,620.065857 477.044647,620.643860 
	C479.415833,621.410706 481.883484,621.884216 484.242828,622.681519 
	C488.400482,624.086426 490.999298,626.842773 491.195496,631.436096 
	C491.392303,636.043884 488.927399,639.238770 485.005554,640.807556 
	C478.438599,643.434448 471.650391,643.132507 465.052612,640.585205 
	C462.356598,639.544312 461.131622,637.729675 462.550842,634.678101 
	C463.873230,631.834900 465.665894,631.776428 468.219666,632.905029 
	C471.281891,634.258423 474.514709,635.141541 477.933350,634.539429 
	C479.118713,634.330627 480.286316,633.815491 480.472229,632.482788 
	C480.672089,631.050110 479.557831,630.437988 478.384644,630.104248 
	C475.984253,629.421570 473.545685,628.869568 471.156586,628.151550 
	C466.674164,626.804321 462.645813,624.842712 461.920776,619.530273 
	C461.262573,614.707947 463.633209,611.252869 469.664825,608.245972 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M634.682983,611.266113 
	C638.656067,619.933899 642.526306,628.211609 646.215088,636.569336 
	C646.948914,638.232178 649.570007,640.701843 646.124268,641.860352 
	C643.489990,642.746094 640.144836,643.397705 637.995483,640.230835 
	C633.190063,633.150391 623.323792,632.941589 618.880066,639.905151 
	C616.635620,643.422302 613.169983,642.924683 610.486389,641.681091 
	C607.628296,640.356689 610.093018,637.845276 610.873901,636.109436 
	C614.555237,627.926025 618.428162,619.828735 622.232910,611.700867 
	C624.774658,606.271057 630.855408,605.876709 634.682983,611.266113 
M625.549500,625.681702 
	C626.615234,629.111206 628.944885,627.486267 631.176819,627.305847 
	C631.238708,624.339783 630.420044,622.022583 628.709717,619.903259 
	C626.469238,621.097839 626.511902,623.291687 625.549500,625.681702 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M512.776611,652.659668 
	C516.738647,651.647278 517.286133,654.844238 519.185059,656.922241 
	C516.459473,659.401978 514.075378,661.783569 516.958740,665.638855 
	C517.814941,666.783752 516.185852,667.988831 514.695557,668.147827 
	C511.995300,668.436218 510.247345,667.267761 509.469543,664.613708 
	C508.865204,662.551575 510.531860,660.340759 508.886383,658.029419 
	C507.218079,658.676453 505.267700,658.487671 507.243622,661.423706 
	C509.353912,664.559326 507.508942,667.480347 504.352753,667.776733 
	C498.597748,668.317261 492.734711,667.986084 486.940399,667.631714 
	C485.504669,667.543823 484.159607,665.973511 482.372498,664.828003 
	C479.443237,669.271240 472.980865,667.080383 469.507690,671.625549 
	C468.478516,672.972351 465.709961,671.625549 466.132263,669.141235 
	C466.883484,664.721924 467.854126,660.305603 469.198853,656.034668 
	C469.983704,653.541931 477.074432,652.945618 480.085999,654.902283 
	C481.046112,655.526123 481.822510,656.432678 482.914703,657.419189 
	C487.407288,654.213135 492.095490,651.610413 497.014923,657.231262 
	C500.182709,653.792664 503.783020,652.973633 507.894684,654.947449 
	C510.248688,656.077515 510.974426,653.603821 512.776611,652.659668 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M475.782532,321.799255 
	C470.012421,326.550293 464.011902,326.966492 457.885223,323.737183 
	C453.002106,321.163300 449.389404,313.432617 450.179871,308.002289 
	C451.047150,302.044617 454.216370,297.711395 460.116455,296.210022 
	C466.373566,294.617798 472.562653,295.215302 476.935730,300.607880 
	C481.167847,305.826569 481.429810,311.850464 478.643036,317.918396 
	C478.034821,319.242737 476.927643,320.337891 475.782532,321.799255 
M471.335480,317.858124 
	C472.856873,315.700500 473.685394,313.409088 472.037933,310.996216 
	C470.631226,308.935974 468.378479,308.653656 466.263428,309.307587 
	C462.886597,310.351624 459.522522,311.968658 459.636139,316.157440 
	C459.717621,319.161407 464.516083,320.882874 468.704407,319.695801 
	C469.461212,319.481323 470.106628,318.873932 471.335480,317.858124 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M602.059387,616.445435 
	C600.133606,623.602112 601.346741,630.592773 601.046692,637.529419 
	C600.890381,641.144653 599.432129,642.559998 595.807739,642.332764 
	C592.843018,642.146790 591.684509,640.832153 591.713013,637.965088 
	C591.769226,632.315002 591.536987,626.657837 591.738220,621.014832 
	C591.884033,616.923218 590.459900,615.105469 586.218079,615.683167 
	C583.404053,616.066528 580.819092,615.872986 580.638123,611.945557 
	C580.444031,607.733154 583.067383,607.579163 586.170288,607.615356 
	C593.315979,607.698669 600.463623,607.695068 607.609558,607.629578 
	C610.344849,607.604492 612.202637,608.301697 612.036316,611.495483 
	C611.874634,614.599976 610.378662,616.047058 607.193420,615.579712 
	C605.540833,615.337219 603.874084,615.217773 602.059387,616.445435 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M279.123047,411.514038 
	C270.970520,412.705750 265.902496,409.464996 265.091705,402.906982 
	C264.397949,397.295624 267.620941,392.420563 272.840057,391.186951 
	C278.704742,389.800720 283.463806,392.149109 285.373749,397.371887 
	C287.486481,403.149231 285.242554,408.516693 279.123047,411.514038 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M464.662354,666.757446 
	C458.902954,669.202881 454.033142,668.945435 450.603394,663.234497 
	C448.123291,668.120239 443.999542,669.530945 440.127258,667.140869 
	C437.101166,665.273132 436.507385,660.304138 438.908203,656.939819 
	C441.568848,653.211487 446.480133,653.296021 451.765625,657.344788 
	C454.782074,654.620239 458.241547,653.784546 462.549988,654.125854 
	C466.957428,654.474976 467.713074,656.130981 466.706055,659.775696 
	C466.092163,661.997620 466.343109,664.445190 464.662354,666.757446 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M432.971405,667.740479 
	C427.563782,666.453064 432.419495,662.020020 429.794891,659.587524 
	C426.891510,659.978333 427.145813,662.795654 426.593872,664.730164 
	C425.865204,667.284241 424.824249,668.761536 422.081329,667.351013 
	C420.434204,666.503967 419.259033,667.534729 417.866241,667.934326 
	C414.409943,668.925659 411.112579,668.590027 408.974182,665.395691 
	C406.955322,662.380005 407.727692,659.276123 409.954010,656.664795 
	C412.516296,653.659546 415.839386,653.158875 419.457825,654.501587 
	C420.771515,654.989075 421.867706,655.919434 421.747284,657.422485 
	C421.619141,659.021606 420.323883,659.527954 418.876617,659.583801 
	C417.246735,659.646729 414.493500,658.144897 414.558289,660.993713 
	C414.632050,664.239624 417.652130,662.003723 419.293030,662.472534 
	C419.898926,662.645691 420.544647,662.679504 421.155182,662.774231 
	C423.002350,659.667603 422.690491,656.130615 423.991150,653.098267 
	C425.227417,650.216064 426.857025,648.040771 429.333618,652.227234 
	C429.784180,652.988892 430.342529,653.402283 431.146759,653.759460 
	C437.299194,656.491394 438.216614,662.423462 432.971405,667.740479 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M565.612671,658.326660 
	C563.764771,659.453857 561.546448,660.489136 563.108887,663.232727 
	C563.811646,664.466797 565.460266,665.348694 564.192139,667.038879 
	C563.302612,668.224365 561.933533,668.390320 560.592041,668.131714 
	C558.634338,667.754456 557.254089,666.591064 557.007019,664.546997 
	C556.773682,662.616211 556.784241,660.656067 556.102600,658.241943 
	C555.870178,664.929688 553.717468,669.297363 546.261658,668.307495 
	C542.213135,667.769958 540.678711,665.284973 540.087646,661.328613 
	C539.999207,660.981018 539.973389,661.014832 539.973389,661.014832 
	C543.266235,653.913879 546.234924,652.538757 554.610168,654.331482 
	C555.689758,654.562561 556.865051,655.698975 557.806702,654.634460 
	C562.076233,649.808289 564.090088,653.222290 566.032227,657.333496 
	C565.831604,658.035217 565.722107,658.180969 565.612671,658.326660 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M317.769958,410.775696 
	C315.362701,400.928406 320.384796,394.536377 329.076752,395.793121 
	C334.342529,396.554504 336.300537,400.227203 334.030701,405.070740 
	C333.468750,406.269867 332.683472,407.433411 332.437744,408.698151 
	C331.809143,411.933105 331.963745,415.489014 327.390015,416.094757 
	C322.996155,416.676697 320.053925,414.775299 317.769958,410.775696 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M540.007446,660.961182 
	C539.105286,665.212219 537.843140,668.743286 532.143066,668.291382 
	C528.349915,667.990662 525.688904,666.925110 524.851929,663.069275 
	C523.965637,658.986023 526.137329,655.166138 530.090149,654.234192 
	C532.779541,653.600037 535.638184,653.789246 537.228638,650.797974 
	C537.678894,649.950989 538.928284,649.877686 539.929626,650.374451 
	C541.187805,650.998657 541.343872,652.158020 541.176392,653.338745 
	C540.852295,655.623657 540.419922,657.893311 540.002930,660.592041 
	C539.973389,661.014832 539.999207,660.981018 540.007446,660.961182 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M566.092529,657.661987 
	C569.561035,652.644653 574.502625,654.206177 579.014526,654.267700 
	C579.635254,654.276184 580.694885,655.141174 580.784180,655.726685 
	C581.376038,659.606201 581.014893,663.799316 578.646179,666.648376 
	C576.292969,669.478699 572.059326,668.779053 568.858032,667.515869 
	C565.127441,666.043945 565.005676,662.492615 565.613220,658.660034 
	C565.722107,658.180969 565.831604,658.035217 566.092529,657.661987 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M323.985596,335.033569 
	C329.800049,332.391388 330.773315,332.534973 333.539001,336.135437 
	C333.370422,337.175781 332.604431,337.389008 331.727112,337.431793 
	C329.625427,337.534271 328.024200,338.371124 328.288727,340.712921 
	C328.559174,343.106659 330.415161,342.813995 332.127472,342.459808 
	C333.566742,342.162109 334.942719,342.333160 335.403839,343.893738 
	C335.986694,345.866486 334.675812,347.100861 333.085968,347.786285 
	C329.991241,349.120544 326.812775,349.319733 324.281281,346.696716 
	C320.867035,343.158966 321.330780,339.231567 323.985596,335.033569 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M354.873871,324.668945 
	C348.831390,320.328613 348.159088,315.488739 353.167999,311.599884 
	C356.255768,316.108032 358.850739,321.651367 365.994019,320.007782 
	C364.642700,325.019836 362.546173,325.838013 354.873871,324.668945 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M293.912292,315.189575 
	C294.835693,311.135925 297.138611,309.217468 300.812256,310.310242 
	C304.099609,311.288025 305.428558,314.029694 304.546173,317.366455 
	C303.663910,320.702942 300.976440,321.533112 297.927643,320.988617 
	C295.065308,320.477386 293.895447,318.392670 293.912292,315.189575 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M336.952362,372.939209 
	C332.017883,372.125977 331.031616,369.019348 332.153595,365.231659 
	C333.027313,362.282043 335.621765,361.386200 338.539490,362.112854 
	C340.838745,362.685394 342.194977,364.273438 342.290588,366.718811 
	C342.425995,370.181183 340.893127,372.366394 336.952362,372.939209 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M407.116577,665.079712 
	C406.531677,668.000793 404.912903,668.099060 402.613617,667.875671 
	C399.590668,667.582092 396.503357,667.951355 393.443726,668.035767 
	C391.790680,668.081299 390.413513,667.509644 390.093719,665.789978 
	C389.773346,664.067261 391.004272,662.361328 392.391296,662.610291 
	C396.606018,663.367065 400.702148,662.110840 404.842438,662.424377 
	C406.132843,662.522095 406.862610,663.425598 407.116577,665.079712 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
